import request from '@/utils/system/request';

export interface HotTag{
  tagId: number,
  tagName: string,
  tagCount: number
}

export interface SimpleBlog{
  blogId: number,
  title: string
}

export interface BlogDetailDto {
  blogId: number,
  blogCoverImage: string,
  blogContent: string,
  blogCategoryName: string,
  blogCategoryIcon: string,
  blogTitle: string,
  blogViews: number,
  commentCount: number,
  createTime: string,
  enableComment: number,
  blogTags: string[]
}

export interface SearchPage{
  pageNum: number,
  tagName?: string,
  keyWord?:string
}
export interface BlogSearchDto {
  blogName: string | null;
  tagName: string | null;
  categoryName: string | null;
}

export async function findList(page: number,size: number,data: BlogSearchDto){
  return await request({
    url: `/blog/findList/${size}/${page}`,
    method: 'post',
    data
  });
}

export async function searchDate(data: object){
  return await request({
    url: '/blog/searchBlogs',
    method: 'get',
    params: data 
  })
}

export async function getBlogDetail(data: object){
  return await request({
    url: '/blog/blogDetail',
    method: 'get',
    params: data 
  })
}

export async function getHotTags(){
  return await request({
    url: '/blog/hotTags',
    method: 'get'
  })
}

export function getLatestBlogs(){
  return request({
    url: '/blog/latestBlogs',
    method: 'get'
  })
}

export async function getHotBlogs(){
  return await request({
    url: '/blog/hotBlogs',
    method: 'get'
  })
}

export async function searchTag(data: object){
  return await request({
    url: 'blog/blogsByTag',
    method: 'get',
    params: data
  })
}