import request from "@/utils/system/request";

export interface LoginObj {
  userName: string;
  password: string;
  chaptchaID: string;
  chaptchaCode: string;
}

export async function getCaptcha() {
  return await request({
    url: "/admin/captcha",
    method: "get",
  });
}

export function Login(data: LoginObj) {
  return request({
    url: "/admin/Login",
    method: "post",
    headers: { "Content-Type": "application/json; charset=utf-8" },
    data: data,
  });
}

export function findAccountInfo() {
  return request({
    url: "/admin/FindAccountInfo",
    method: "get",
  });
}
