import request from "@/utils/system/request";

export interface BlogSearchDto {
  blogName: string | null;
  tagName: string | null;
  categoryName: string | null;
}

export interface MenuList {
  index: string;
  title: string;
  menuItem: MenuItem[];
  icon?: string;
}

export interface MenuItem {
  index: string;
  title: string;
  route: string;
  icon?: string;
}

export interface BlogSaveDto {
  blogId: number;
  blogTitle: string;
  blogSubUrl: string;
  blogCategoryId: number;
  blogTags: string[];
  blogContent: string;
  blogCoverImage: string;
  blogStatus: boolean;
  enableComment: boolean;
}

export default {
  findList(page: number, size: number, data: BlogSearchDto) {
    return request({
      url: `/admin/blog/FindList/${size}/${page}`,
      method: "post",
      data: data,
    });
  },
  findById(blogId: number) {
    return request({
      url: `/admin/blog/${blogId}`,
      method: "get",
    });
  },
  insertBlog(data: BlogSaveDto) {
    return request({
      url: "/admin/blog/SaveBlog",
      method: "post",
      data,
    });
  },
  getRandomCover() {
    return request({
      url: "/admin/blog/GetRandomCover",
      method: "get",
    });
  },
  deleteBlog(blogids: number[]) {
    return request({
      url: "/admin/blog/delete",
      method: "post",
      data: blogids,
    });
  },
  uploadImage(data: any) {
    return request({
      url: "admin/blogs/md/uploadfile",
      method: "post",
      data,
      headers: { "content-type": "multipart/form-data" },
    });
  },
};
