import mdeditor from "@/plugins/vMdEditor";
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import useElementUi from "@/plugins/element-ui";
import "element-plus/theme-chalk/el-message.css";
import "element-plus/theme-chalk/el-loading.css";
import useVxeTable from "@/plugins/vxe-table";

const app = createApp(App);
app.use(mdeditor);
app.use(useElementUi);
app.use(useVxeTable);
app.use(router).mount("#app");
