import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, KeepAlive as _KeepAlive, vShow as _vShow, withDirectives as _withDirectives, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-76e611f2"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "layout-drawer"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Menu = _resolveComponent("Menu");

  const _component_el_scrollbar = _resolveComponent("el-scrollbar");

  const _component_el_aside = _resolveComponent("el-aside");

  const _component_Header = _resolveComponent("Header");

  const _component_el_header = _resolveComponent("el-header");

  const _component_router_view = _resolveComponent("router-view");

  const _component_el_main = _resolveComponent("el-main");

  const _component_el_container = _resolveComponent("el-container");

  const _component_el_drawer = _resolveComponent("el-drawer");

  return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_el_container, {
    style: {
      "height": "100vh"
    }
  }, {
    default: _withCtx(() => [_createVNode(_component_el_aside, {
      width: _ctx.allStore.menuWidth
    }, {
      default: _withCtx(() => [_createVNode(_component_el_scrollbar, null, {
        default: _withCtx(() => [!_ctx.allStore.isMobile ? (_openBlock(), _createBlock(_component_Menu, {
          key: 0
        })) : _createCommentVNode("", true)]),
        _: 1
      })]),
      _: 1
    }, 8, ["width"]), _createVNode(_component_el_container, null, {
      default: _withCtx(() => [_createVNode(_component_el_header, null, {
        default: _withCtx(() => [_createVNode(_component_Header)]),
        _: 1
      }), _createVNode(_component_el_main, null, {
        default: _withCtx(() => [(_openBlock(), _createBlock(_KeepAlive, null, [_createVNode(_component_router_view)], 1024))]),
        _: 1
      })]),
      _: 1
    })]),
    _: 1
  }), _createElementVNode("div", _hoisted_1, [_ctx.allStore.isMobile ? _withDirectives((_openBlock(), _createBlock(_component_el_drawer, {
    key: 0,
    size: "250px",
    modelValue: _ctx.drawer,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.drawer = $event),
    title: "博客",
    direction: "ltr",
    "with-header": false
  }, {
    default: _withCtx(() => [_createVNode(_component_Menu)]),
    _: 1
  }, 8, ["modelValue"])), [[_vShow, !_ctx.allStore.isCollapse]]) : _createCommentVNode("", true)])], 64);
}