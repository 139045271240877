import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_icon = _resolveComponent("el-icon");

  return _openBlock(), _createBlock(_component_el_icon, {
    style: {
      "cursor": "pointer"
    },
    title: _ctx.isFullscreen ? '退出全屏' : '全屏',
    onClick: _ctx.toggle,
    size: 16
  }, {
    default: _withCtx(() => [(_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.isFullscreen ? 'FullScreen' : 'FullScreen')))]),
    _: 1
  }, 8, ["title", "onClick"]);
}