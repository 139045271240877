import request from "@/utils/system/request";

export default {
  findList(page: number,size:number) {
    return request({
      url: `admin/blogTag/FindList/${size}/${page}`,
      method: "get"
    })
  },
  findSimpleTag() {
    return request({
      url: "admin/blogTag/FindSimpleTag",
      method: "get",
    });
  },
  deleteList(ids: number[]) {
    return request({
      url: "admin/blogTag/DeleteList",
      method: "post",
      data: ids
    });
  }
}
