import tools from "@/utils/tools";
import { defineComponent, onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
export default defineComponent({
  emits: ["active"],

  setup(props, {
    attrs,
    slots,
    emit
  }) {
    const route = useRoute();
    const router = useRouter();
    const menuList = ref([{
      index: "1",
      title: "Dashboard",
      icon: "Platform",
      menuItem: [{
        index: "1-1",
        title: "Dashboard",
        route: "/admin/dashboard",
        icon: "Monitor"
      }, {
        index: "1-2",
        title: "地图组件",
        route: "/admin/map",
        icon: "MapLocation"
      }, {
        index: "1-3",
        title: "发布博客",
        route: "/admin/edit",
        icon: "Edit"
      }]
    }, {
      index: "2",
      title: "管理模块",
      icon: "List",
      menuItem: [{
        index: "2-1",
        title: "博客管理",
        route: "/admin/blogs",
        icon: "Postcard"
      }, {
        index: "2-2",
        title: "评论管理",
        route: "/admin/comments",
        icon: "ChatLineSquare"
      }, {
        index: "2-3",
        title: "分类管理",
        route: "/admin/categories",
        icon: "CollectionTag"
      }, {
        index: "2-4",
        title: "标签管理",
        route: "/admin/tags",
        icon: "PriceTag"
      }, {
        index: "2-5",
        title: "友情链接",
        route: "/admin/links",
        icon: "Link"
      }]
    }, {
      index: "3",
      title: "系统管理",
      icon: "Tools",
      menuItem: [{
        index: "3-1",
        title: "系统配置",
        route: "/admin/configurations",
        icon: "Tools"
      }, {
        index: "3-2",
        title: "系统日志",
        route: "/admin/log",
        icon: "Tickets"
      }, {
        index: "3-3",
        title: "修改密码",
        route: "/admin/profile",
        icon: "Edit"
      }, {
        index: "3-4",
        title: "安全退出",
        route: "/admin/logout",
        icon: "ArrowLeft"
      }]
    }]);

    const menuRouter = path => {
      if (path === "/admin/logout") {
        tools.removeToken();
        router.push("/login");
        return;
      }

      router.push(path);
    };

    watch(() => route.fullPath, value => {
      menuList.value.forEach(menu => {
        menu.menuItem.forEach(item => {
          if (value.indexOf(item.route) > -1) {
            emit("active", item.index);
            return;
          }
        });
      });
    });
    onMounted(() => {
      menuList.value.forEach(menu => {
        menu.menuItem.forEach(item => {
          if (route.path.indexOf(item.route) > -1) {
            emit("active", item.index);
            return;
          }
        });
      });
    });
    return {
      menuList,
      menuRouter
    };
  }

});