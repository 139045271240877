import Header from "@/layout/Header/index.vue";
import Menu from "@/layout/Menu/index.vue";
import { findAccountInfo } from "@/api/login";
import store from "@/store";
import { computed, defineComponent, onMounted, reactive, toRefs, watch } from "vue";
export default defineComponent({
  components: {
    Header,
    Menu
  },

  setup() {
    const state = reactive({
      drawer: false
    });
    const allStore = computed(() => store.state);

    const FinAccountInfo = () => {
      findAccountInfo().then(res => {
        store.state.userInfo = res.data;
      });
    };

    onMounted(() => {
      store.calcScreen();
      store.calcMenuWidth(store.state.isCollapse);
      FinAccountInfo();
    });
    watch(() => state.drawer, value => {
      store.setIsCollapse(!value);
    });
    watch(() => store.state.isCollapse, value => {
      state.drawer = !value;
      store.calcMenuWidth(value);
    });
    watch(() => store.state.isMobile, value => {
      if (value) {
        store.setIsCollapse(true);
      }

      store.calcMenuWidth(store.state.isCollapse);
    });
    return {
      allStore,
      ...toRefs(state)
    };
  }

});