import { defineComponent } from "vue";
import { zhCn } from "element-plus/es/locale";
export default defineComponent({
  name: "App",

  setup() {
    return {
      locale: zhCn
    };
  }

});