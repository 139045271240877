import Layout from "@/layout/index.vue";
import Dashboard from "@/views/admin/dashboard/Dashboard.vue";
import mdEditor from "@/views/admin/dashboard/Edit.vue";
import Map from "@/views/admin/dashboard/Map.vue";
import CommentManager from "@/views/admin/manager/CommentManager.vue";
import ConfigManager from "@/views/admin/systemManager/config/index.vue";

const route = [
  {
    path: "/admin",
    name: "后台",
    component: Layout,
    redirect: "/admin/dashboard",
    children: [
      {
        path: "dashboard",
        name: "管理首页",
        component: Dashboard,
      },
      {
        path: "map",
        name: "地图组件",
        component: Map,
      },
      {
        path: "edit/:id(\\d+)?",
        name: "发布博客",
        component: mdEditor,
      },
      {
        path: "blogs",
        name: "博客管理",
        component: () => import("@/views/admin/manager/blog/index.vue"),
      },
      {
        path: "comments",
        name: "评论管理",
        component: CommentManager,
      },
      {
        path: "categories",
        name: "分类管理",
        component: () => import("@/views/admin/manager/category/index.vue"),
      },
      {
        path: "tags",
        name: "标签管理",
        component: () => import("@/views/admin/manager/tag/index.vue"),
      },
      {
        path: "links",
        name: "友链管理",
        component: () => import("@/views/admin/manager/link/index.vue"),
      },
      {
        path: "log",
        name: "系统日志",
        component: () => import("@/views/admin/systemManager/log/index.vue"),
      },
      {
        path: "configurations",
        name: "系统配置",
        component: () => import("@/views/admin/systemManager/config/index.vue"),
      },
      {
        path: "profile",
        name: "修改密码",
        component: () => import("@/views/admin/systemManager/profile/index.vue"),
      },
    ],
  },
];

export default route;
