import { getCaptcha, Login } from "@/api/login";
import { ElMessage } from "element-plus";
import { defineComponent, onMounted, reactive, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import tools from "@/utils/tools";
export default defineComponent({
  setup(props) {
    const chaptch = ref("");
    const router = useRouter();
    const route = useRoute();
    const form = reactive({
      title: "登录",
      name: "",
      password: "",
      chaptcha: "",
      chaptchaId: "",
      loading: false
    });

    const checkForm = () => {
      return new Promise((resolve, reject) => {
        if (form.name === "") {
          ElMessage.warning({
            message: "用户名不能为空",
            type: "warning"
          });
          return;
        }

        if (form.password === "") {
          ElMessage.warning({
            message: "密码不能为空",
            type: "warning"
          });
          return;
        }

        if (form.chaptcha === "") {
          ElMessage.warning({
            message: "验证码不能为空",
            type: "warning"
          });
          return;
        }

        resolve(true);
      });
    };

    const submit = () => {
      checkForm().then(() => {
        form.loading = true;
        let params = {
          userName: form.name,
          password: form.password,
          chaptchaID: form.chaptchaId,
          chaptchaCode: form.chaptcha
        };
        Login(params).then(async res => {
          console.log(res);

          if (res.code === 200) {
            tools.setToken(res.data);
            ElMessage.success({
              message: "登录成功",
              type: "success",
              showClose: true,
              duration: 1000
            });
            await router.push("/admin");
          } else {
            getCaptcha();
          }
        }).catch(error => {
          if (error.code == 201) {
            form.chaptcha = "";
            form.chaptchaId = "";
          }

          getchaptch();
        }).finally(() => {
          form.loading = false;
          form.chaptcha = "";
        });
      });
    };

    const getchaptch = async () => {
      await getCaptcha().then(res => {
        console.log(res);
        form.chaptchaId = res.data.code;
        chaptch.value = res.data.captch;
      });
    };

    onMounted(() => {
      getchaptch();
    });
    return {
      form,
      chaptch,
      submit,
      getchaptch
    };
  }

});