import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import { staticRoutes } from './staticRoutes';
import admins from './staticRoutes/admin';

const routes: Array<RouteRecordRaw> = [...staticRoutes,...admins];

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach((to,form,next) => {
  next();
})

export default router
