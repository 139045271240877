import { getBlogDetail } from "@/api/visitBlog";
import BlogHeader from "@/components/BlogHeader.vue";
import VueMarkdownEditor, { xss } from "@kangc/v-md-editor";
import { defineComponent, onMounted, ref, reactive, toRefs } from "vue";
import { useRoute } from "vue-router";
export default defineComponent({
  components: {
    BlogHeader
  },

  setup() {
    const state = reactive({
      showviewer: false,
      previewSrcList: []
    });
    const content = ref("");
    const blog = ref(null);
    const route = useRoute();

    const getData = async () => {
      var _blog$value;

      let res = await getBlogDetail({
        blogId: route.params.id
      });
      console.log(res);
      blog.value = res.data;
      content.value = xss.process(VueMarkdownEditor.vMdParser.themeConfig.markdownParser.render((_blog$value = blog.value) === null || _blog$value === void 0 ? void 0 : _blog$value.blogContent));
    };

    const methods = {
      closeViewer() {
        state.showviewer = false;
        document.documentElement.style.overflowY = "auto";
      }

    };
    onMounted(async () => {
      await getData();
      let imgs = document.querySelector(".markdown-body").getElementsByTagName("img");

      for (let i = 0; i < imgs.length; i++) {
        const element = imgs[i];
        element.addEventListener("click", () => {
          let src = element.getAttribute("src");
          state.previewSrcList = [];

          if (src != null) {
            state.previewSrcList.push(src);
          }

          state.showviewer = true;
          document.documentElement.style.overflowY = "hidden";
        });
      }
    });
    return {
      blog,
      content,
      ...methods,
      ...toRefs(state)
    };
  }

});