import { defineComponent, onMounted, reactive, toRefs } from "vue";
import service from "@/api/admin/dashboard";
import router from "@/router";
export default defineComponent({
  setup() {
    const state = reactive({
      options: [{
        id: 1,
        title: "新增博客",
        describe: "记心中所想",
        textColor: "#fff",
        background: "#17a2b8",
        icon: "Edit",
        footText: "let's go",
        route: "/admin/edit"
      }, {
        id: 2,
        title: "0",
        describe: "总文章数",
        textColor: "#fff",
        background: "#28a745",
        icon: "Notebook",
        footText: "More info",
        route: "/admin/blogs"
      }, {
        id: 3,
        title: "0",
        describe: "收到评论数",
        textColor: "#fff",
        background: "#007bff",
        icon: "ChatDotRound",
        footText: "More info",
        route: "/admin/comments"
      }, {
        id: 4,
        title: "0",
        describe: "分类数量",
        textColor: "#000",
        background: "#ffc107",
        icon: "CollectionTag",
        footText: "More info",
        route: "/admin/categories"
      }, {
        id: 5,
        title: "0",
        describe: "标签总数",
        textColor: "#fff",
        background: "#dc3545",
        icon: "Collection",
        footText: "More info",
        route: "/admin/tags"
      }, {
        id: 6,
        title: "0",
        describe: "友情链接",
        textColor: "#fff",
        background: "#343a40",
        icon: "Link",
        footText: "More info",
        route: "/admin/links"
      }]
    });
    const methods = {
      findByDashboadrd() {
        service.findByDashboadrd().then(res => {
          const data = res.data;
          state.options[1].title = data.blogTotal;
          state.options[2].title = data.commentTotal;
          state.options[3].title = data.categoryTotal;
          state.options[4].title = data.tagTotal;
          state.options[5].title = data.linkTotal;
        });
      },

      goRoute(route) {
        router.push(route);
      }

    };
    onMounted(() => {
      methods.findByDashboadrd();
    });
    return { ...toRefs(state),
      ...methods
    };
  }

});