import store from "@/store";
import tools from "@/utils/tools";
import { computed, defineComponent } from "vue";
import { useRouter } from "vue-router";
import BreadcrumbVue from "./Breadcrumb.vue";
import FullScreenVue from "./theme/FullScreen.vue";
export default defineComponent({
  components: {
    BreadcrumbVue,
    FullScreenVue
  },

  setup() {
    const router = useRouter();
    const isCollapse = computed(() => store.getIsCollapse());

    const openStateChange = () => {
      store.setIsCollapse(!isCollapse.value);
    };

    const loginOut = () => {
      tools.removeToken();
      router.push("/login");
    };

    const nickName = computed(() => store.state.userInfo.nickName);
    return {
      nickName,
      isCollapse,
      openStateChange,
      loginOut
    };
  }

});