import error404 from '@/assets/image/404.png';
import error404Cloud from '@/assets/image/404_cloud.png';
import { defineComponent } from 'vue';
export default defineComponent({
  setup() {
    return {
      error404,
      error404Cloud
    };
  },

  methods: {
    go() {
      this.$router.push('/');
    }

  }
});