import { baseURL } from "@/config/config";
import router from "@/router";
import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from "axios";
import { ElMessage } from "element-plus";
import tools from "../tools";

const service: AxiosInstance = axios.create({
  timeout: 60 * 1000,
  baseURL: baseURL,
});

// 请求统一处理
service.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    let token = tools.getToken();
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }

    return config;
  },
  (error: AxiosError) => {
    console.log(error);
    return Promise.reject(error);
  }
);

// 统一接受请求
service.interceptors.response.use(
  (response: AxiosResponse) => {
    // 图片流什么之类的资源直接放行
    if (response.data instanceof Blob) {
      return response;
    }
    const data = response.data;
    if (Object.prototype.hasOwnProperty.call(data,"code")) {
      if(data.code === 401)
      {
        ElMessage({
          message: "未授权或已过期请重新登录!",
          type: "error",
          duration: 3 * 1000,
        });
  
        router.push("/login");
      }

      if(data.code === 201)
      {
        ElMessage({
          message: "登录失败: " + data.message,
          type: "error",
          duration: 3 * 1000,
        });
        return;
      }

      if(data.code === 500)
      {
        ElMessage({
          message: "服务器内部错误: " + data.message,
          type: "error",
          duration: 3 * 1000,
        });
        return;
      }

      if(data.code === -1)
      {
        ElMessage({
          message: data.message,
          type: "warning",
          duration: 3 * 1000,
        });
        return;
      }

      return data;
    } else {
      return Promise.reject(data);
    }
  },
  (error: any) => {
    if (error.response.status === 401) {
      ElMessage({
        message: "token过期或未授权",
        type: "error",
        duration: 2 * 1000,
      });

      router.push("/login");
    }
    return Promise.reject(error);
  }
);

export default service;
