import { defineComponent, ref } from 'vue';
export default defineComponent({
  props: {
    headPortraitUrl: String,
    imageUrl: String,
    nickname: String,
    category: String,
    blogNmae: String,
    dateTime: String
  },

  setup(props) {
    var _props$dateTime;

    const headPortrai = ref(props.headPortraitUrl);
    const createTime = (_props$dateTime = props.dateTime) === null || _props$dateTime === void 0 ? void 0 : _props$dateTime.split(" ")[0];
    return {
      createTime,
      headPortrai
    };
  }

});