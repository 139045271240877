import { defineComponent } from 'vue';
export default defineComponent({
  setup() {
    const links = [{
      id: 1,
      url: "/",
      title: "主页"
    }, {
      id: 2,
      url: "/link",
      title: "友链"
    }, {
      id: 3,
      url: "/about",
      title: "关于"
    }];
    return {
      links
    };
  }

});