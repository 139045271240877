import { useFullscreen } from "@vueuse/core";
import { defineComponent } from "vue";
export default defineComponent({
  setup() {
    const {
      isFullscreen,
      toggle
    } = useFullscreen();
    return {
      isFullscreen,
      toggle
    };
  }

});