import request from "@/utils/system/request";
import { IPageQuery } from "./pageQuery";

export interface IBlogCategory {
  id: number;
  categoryName: string;
  categoryIcon: string;
  categoryRank: number;
  isDeleted: boolean;
  createTime: string;
}

export interface BlogCategorySearchDto {
  categoryName: string | null;
}

export enum CategoryType {
  AddCategory,
  AlterCategory,
  RemoveCategory,
}

export default {
  findList(page: number, size: number, data: BlogCategorySearchDto) {
    return request({
      url: `/admin/blogCategory/FindList/${size}/${page}`,
      method: "post",
      data: data,
    });
  },
  getSimpleCategory() {
    return request({
      url: "admin/blogCategory/FindSimpleCategory",
      method: "get",
    });
  },
  findForm(id: number) {
    return request({
      url: "admin/blogCategory/FindForm",
      method: "get",
      params: {id}
    });
  },
  getCategoryUrl() {
    return request({
      url: "admin/blogCategory/GetCategoryUrl",
      method: "get",
    });
  },
  saveForm(data: object) {
    return request({
      url: "admin/blogCategory/SaveForm",
      method: "post",
      data
    });
  },
  deleteList(ids: number[]) {
    return request({
      url: "admin/blogCategory/DeleteList",
      method: "post",
      data: ids
    });
  },
};
