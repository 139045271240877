import VMdEditor from '@kangc/v-md-editor'
import '@kangc/v-md-editor/lib/style/base-editor.css'
import '@kangc/v-md-editor/lib/theme/style/vuepress.css'
import vuepressTheme from '@kangc/v-md-editor/lib/theme/vuepress.js'
// Prism
import Prism from 'prismjs'
// highlight code
import 'prismjs/components/prism-json'


export default (app: any) => {
  VMdEditor.use(vuepressTheme, {
    Prism,
  });
  
  app.use(VMdEditor);
}
