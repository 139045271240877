import { ElMessage } from "element-plus"
import { EpPropMergeType } from "element-plus/es/utils";

const tools = {
  setToken(token: string) {
    localStorage.setItem("token",token);
  },
  getToken()
  {
    return localStorage.getItem("token");
  },
  removeToken()
  {
    localStorage.removeItem("token");
  },
  message(msg:string, type:EpPropMergeType<StringConstructor, "success" | "warning" | "info" | "error", unknown> | undefined = "success",duration: number = 3000) {
    ElMessage({
      message: msg,
      type: type,
      duration
    })
  }
}

export default tools;