import error401 from '@/assets/image/401.gif';
import { defineComponent } from 'vue';
export default defineComponent({
  setup() {
    return {
      error401
    };
  },

  methods: {
    go() {
      this.$router.push('/');
    }

  }
});