import store from "@/store";
import { computed, defineComponent, ref } from "vue";
import MenuItem from "./MenuItem.vue";
export default defineComponent({
  components: {
    MenuItem
  },

  setup() {
    const active = ref("1-1");
    const isCollapse = computed(() => store.getIsCollapse());
    const isMobile = computed(() => store.state.isMobile);

    const handleOpen = (key, keyPath) => {};

    const handleClose = (key, keyPath) => {};

    const setctive = text => {
      active.value = text;
    };

    return {
      active,
      isCollapse,
      isMobile,
      handleOpen,
      handleClose,
      setctive
    };
  }

});