import VueMarkdownEditor, { xss } from "@kangc/v-md-editor";
import { computed, defineComponent, nextTick, onMounted, reactive, Ref, ref, toRefs } from "vue";
import categoryservice from "@/api/admin/category";
import blogService from "@/api/admin/blog";
import tagService from "@/api/admin/tag";
import { ElMessage } from "element-plus";
import router from "@/router";
import tools from "@/utils/tools";
import { useRoute } from "vue-router";
export default defineComponent({
  setup() {
    const route = useRoute();
    const toolbar = "undo redo clear | h bold italic strikethrough quote tip | ul ol table hr | link image code | save";
    const selectRef = ref(null);
    const state = reactive({
      vm: {
        blogId: 0,
        blogTitle: "",
        blogSubUrl: "",
        blogTags: [],
        blogCategoryId: null,
        blogStatus: true,
        enableComment: true,
        blogCoverImage: "",
        blogContent: ""
      },
      categoryItem: [],
      tagItem: [],
      inputVisible: false,
      inputValue: "",
      isShowInput: true,
      drawer: false,
      subloading: false,
      loading: false
    });
    const methods = {
      findById() {
        state.loading = true;
        blogService.findById(state.vm.blogId).then(res => {
          console.log(res);

          if (res.code === 200) {
            state.vm.blogTitle = res.data.blogTitle;
            state.vm.blogSubUrl = res.data.blogSubUrl;
            state.vm.blogTags = res.data.blogTags.split(",");
            state.vm.blogCategoryId = res.data.blogCategoryId;
            state.vm.blogStatus = res.data.blogStatus;
            state.vm.enableComment = res.data.enableComment;
            state.vm.blogCoverImage = res.data.blogCoverImage;
            state.vm.blogContent = res.data.blogContent;
          }
        }).finally(() => {
          state.loading = false;
        });
      },

      getSimpleCategory() {
        categoryservice.getSimpleCategory().then(res => {
          console.log(res);
          state.categoryItem = res.data;
        });
      },

      findSimpleTag() {
        tagService.findSimpleTag().then(res => {
          state.tagItem = res.data;
          console.log(state.tagItem);
        });
      },

      getRandomCover() {
        blogService.getRandomCover().then(res => {
          state.vm.blogCoverImage = res.data;
        });
      },

      insertBlog() {
        state.subloading = true;
        blogService.insertBlog(state.vm).then(res => {
          if (res.code === 200) {
            state.drawer = false;
            ElMessage({
              message: "保存成功",
              type: "success",
              duration: 3 * 1000
            });
            methods.goBlogs();
          }
        }).finally(() => {
          state.subloading = false;
        });
      },

      handleClose(tag) {
        state.vm.blogTags.splice(state.vm.blogTags.indexOf(tag), 1);
        state.isShowInput = true;
      },

      handleInputConfirm() {
        console.log(state.inputValue);

        if (state.inputValue) {
          let value = state.vm.blogTags.filter(d => d == state.inputValue)[0];

          if (!value) {
            state.vm.blogTags.push(state.inputValue);

            if (state.vm.blogTags.length >= 6) {
              state.isShowInput = false;
            }
          }
        }

        state.inputVisible = false;
        state.inputValue = "";
      },

      showInput() {
        state.inputVisible = true;
        nextTick(() => {
          selectRef.value.focus();
        });
      },

      ejectDrawer() {
        if (state.vm.blogTitle === "") {
          tools.message("请输入标题", "warning");
          return;
        }

        if (state.vm.blogSubUrl === "") {
          tools.message("请输入路径", "warning");
          return;
        }

        if (state.vm.blogTags.length === 0) {
          tools.message("请填写标签", "warning");
          return;
        }

        if (state.vm.blogCategoryId === 0) {
          tools.message("请选择分类", "warning");
          return;
        }

        if (state.vm.blogContent === "") {
          tools.message("请输入内容", "warning");
          return;
        }

        state.drawer = true;
      },

      onCancel() {
        state.drawer = false;
      },

      goBlogs() {
        router.push("/admin/blogs");
      },

      handleUploadImage(event, insertImage, files) {
        if (files.length > 0) {
          let formData = new FormData();
          formData.append("file", files[0]);
          console.log(formData);
          blogService.uploadImage(formData).then(res => {
            console.log(res);
            insertImage({
              url: res.data
            });
          });
        }
      }

    };
    onMounted(() => {
      console.log(route.params.id);

      if (route.params.id) {
        state.vm.blogId = route.params.id;
        methods.findById();
      } else {
        methods.getRandomCover();
      }

      methods.getSimpleCategory();
      methods.findSimpleTag();
    });
    return {
      toolbar,
      selectRef,
      ...toRefs(state),
      ...methods
    };
  },

  mounted() {
    this.$nextTick(() => {});
  }

});