import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-be80fd58"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "container"
};
const _hoisted_2 = {
  class: "post-heading"
};
const _hoisted_3 = {
  class: "title"
};
const _hoisted_4 = {
  class: "meta"
};
const _hoisted_5 = {
  class: "tags"
};
const _hoisted_6 = ["innerHTML"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_button = _resolveComponent("el-button");

  const _component_BlogHeader = _resolveComponent("BlogHeader");

  const _component_el_image_viewer = _resolveComponent("el-image-viewer");

  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_BlogHeader, null, {
    default: _withCtx(() => {
      var _ctx$blog, _ctx$blog2, _ctx$blog3, _ctx$blog4, _ctx$blog5;

      return [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("h1", _hoisted_3, _toDisplayString((_ctx$blog = _ctx.blog) === null || _ctx$blog === void 0 ? void 0 : _ctx$blog.blogTitle), 1), _createElementVNode("span", _hoisted_4, _toDisplayString((_ctx$blog2 = _ctx.blog) === null || _ctx$blog2 === void 0 ? void 0 : _ctx$blog2.createTime) + " " + _toDisplayString((_ctx$blog3 = _ctx.blog) === null || _ctx$blog3 === void 0 ? void 0 : _ctx$blog3.commentCount) + "条评论 " + _toDisplayString((_ctx$blog4 = _ctx.blog) === null || _ctx$blog4 === void 0 ? void 0 : _ctx$blog4.blogViews) + "浏览", 1), _createElementVNode("div", _hoisted_5, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList((_ctx$blog5 = _ctx.blog) === null || _ctx$blog5 === void 0 ? void 0 : _ctx$blog5.blogTags, (tag, index) => {
        return _openBlock(), _createBlock(_component_el_button, {
          key: index
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(tag), 1)]),
          _: 2
        }, 1024);
      }), 128))])])])];
    }),
    _: 1
  }), _createElementVNode("div", null, [_createElementVNode("div", {
    class: "markdown-body",
    innerHTML: _ctx.content
  }, null, 8, _hoisted_6)]), _ctx.showviewer ? (_openBlock(), _createBlock(_component_el_image_viewer, {
    key: 0,
    "url-list": _ctx.previewSrcList,
    onClose: _ctx.closeViewer
  }, null, 8, ["url-list", "onClose"])) : _createCommentVNode("", true)]);
}