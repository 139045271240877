import BlogHeader from "@/components/BlogHeader.vue";
import BlogItem from "@/components/BlogItem.vue";
import SidebarHeaderVue from "@/components/SidebarHeader.vue";
import { defineComponent, onMounted, reactive, ref, toRefs } from "vue";
import { useRouter, useRoute } from "vue-router";
import { findList, getHotBlogs, getHotTags, getLatestBlogs } from "../api/visitBlog";
export default defineComponent({
  components: {
    BlogItem,
    SidebarHeaderVue,
    BlogHeader
  },

  setup() {
    const route = useRoute();
    const router = useRouter();
    const links = [{
      id: 1,
      url: "/",
      title: "主页"
    }, {
      id: 2,
      url: "",
      title: "友链"
    }, {
      id: 3,
      url: "",
      title: "关于"
    }];
    const blogData = ref(null);
    const blogHotTags = ref(null);
    const blogLatest = ref(null);
    const hotBlogs = ref(null);
    const state = reactive({
      search: {
        blogName: null,
        tagName: null,
        categoryName: null
      },
      page: 1,
      size: 8
    });

    const getDateTable = async (index, queryParams) => {
      state.page = index;

      if (queryParams) {
        router.push({
          path: "/" + state.page,
          query: {
            search: queryParams
          }
        });
      } else {
        router.push("/" + state.page);
      }

      await findList(state.page, state.size, state.search).then(res => {
        blogData.value = res.data;
      });
    };

    const searchBlog = async () => {
      await getDateTable(1, state.search.blogName).then(() => {
        window.scrollTo(0, 0);
      });
    }; // 上下翻页 要能根据搜索条件和热门标签翻页


    const getPagePrevious = async () => {
      if (state.page > 1) {
        state.page = state.page - 1;
        await getDateTable(state.page).then(() => {
          window.scrollTo(0, 0);
        });
      }
    };

    const getPageNext = async () => {
      var _blogData$value, _blogData$value2;

      if (state.page == ((_blogData$value = blogData.value) === null || _blogData$value === void 0 ? void 0 : _blogData$value.totalPage)) {
        return;
      }

      let totalPage = (_blogData$value2 = blogData.value) === null || _blogData$value2 === void 0 ? void 0 : _blogData$value2.totalPage;

      if (totalPage && state.page > totalPage) {
        state.page = totalPage;
      } else if (totalPage && state.page < totalPage) {
        state.page += 1;
      }

      await getDateTable(state.page).then(() => {
        window.scrollTo(0, 0);
      });
    };

    const getHotTag = async () => {
      await getHotTags().then(res => {
        blogHotTags.value = res.data;
        window.scrollTo(0, 0);
      });
    };

    const searchHotTag = async title => {
      state.search.tagName = title;
      await getDateTable(1).then(() => {
        window.scrollTo(0, 0);
      });
    };

    const getLatestBlog = async () => {
      await getLatestBlogs().then(res => {
        blogLatest.value = res.data;
        window.scrollTo(0, 0);
      });
    };

    const getHotBlog = async () => {
      await getHotBlogs().then(res => {
        hotBlogs.value = res.data;
        window.scrollTo(0, 0);
      });
    };

    const go = index => {
      router.push({
        name: `详情`,
        params: {
          id: index
        }
      });
    };

    onMounted(() => {
      console.log(route);

      if (route.params.id) {
        let id = Number.parseInt(route.params.id);
        getDateTable(id);
      }

      getHotTag();
      getLatestBlog();
      getHotBlog();
    });
    return {
      links,
      blogData,
      blogHotTags,
      blogLatest,
      hotBlogs,
      getDateTable,
      getPagePrevious,
      getPageNext,
      searchBlog,
      searchHotTag,
      go,
      ...toRefs(state)
    };
  }

});