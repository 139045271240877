import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_icon = _resolveComponent("el-icon");

  const _component_el_menu_item = _resolveComponent("el-menu-item");

  const _component_el_menu_item_group = _resolveComponent("el-menu-item-group");

  const _component_el_sub_menu = _resolveComponent("el-sub-menu");

  return _openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menuList, (menu, index) => {
    return _openBlock(), _createBlock(_component_el_sub_menu, {
      key: index,
      index: menu.index
    }, {
      title: _withCtx(() => [_createVNode(_component_el_icon, null, {
        default: _withCtx(() => [(_openBlock(), _createBlock(_resolveDynamicComponent(menu.icon)))]),
        _: 2
      }, 1024), _createElementVNode("span", null, _toDisplayString(menu.title), 1)]),
      default: _withCtx(() => [_createVNode(_component_el_menu_item_group, null, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(menu.menuItem, (menuItem, itemIndex) => {
          return _openBlock(), _createBlock(_component_el_menu_item, {
            key: itemIndex,
            index: menuItem.index,
            onClick: $event => _ctx.menuRouter(menuItem.route)
          }, {
            default: _withCtx(() => [_createVNode(_component_el_icon, null, {
              default: _withCtx(() => [(_openBlock(), _createBlock(_resolveDynamicComponent(menuItem.icon)))]),
              _: 2
            }, 1024), _createTextVNode(" " + _toDisplayString(menuItem.title), 1)]),
            _: 2
          }, 1032, ["index", "onClick"]);
        }), 128))]),
        _: 2
      }, 1024)]),
      _: 2
    }, 1032, ["index"]);
  }), 128);
}