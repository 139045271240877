/*
 * 路由配置规则：
 *
 * {
 *  path:'',路径
 *  name:'',路由名称，生成menu时menu name
 *  meta:{},额外信息，icon为menu中的icon
 *  children: [], 子路由，menu中的子menu 没有时可为空数组
 * }
 */
import Admin from "@/layout/index.vue";
import BlogDetail from "@/views/BlogDetail.vue";
import BlogList from "@/views/BlogList.vue";
import Login from "@/views/Login.vue";
import Error401 from "@/views/system/401.vue";
import Error404 from "@/views/system/404.vue";
import About from "@/views/system/About.vue";
import Link from "@/views/system/Link.vue";

export const staticRoutes = [
  {
    path: "/",
    redirect: "/1",
  },
  {
    path: "/:id",
    name: "博客",
    component: BlogList,
    children: [],
    meta: {
      icon: "el-icon-s-home",
      keepAlive: true,
    },
  },
  {
    path: "/blog/:id",
    name: "详情",
    component: BlogDetail,
    meta: {
      keepAlive: true,
    },
  },
  {
    path: "/login",
    name: "登录",
    component: Login,
  },
  {
    path: "/link",
    name: "友链",
    component: Link,
  },
  {
    path: "/about",
    name: "关于",
    component: About,
  },
  {
    path: "/:pathMatch(.*)",
    component: Error404,
  },
  {
    path: "/401",
    component: Error401,
  },
  {
    path: "/admin",
    name: "后台",
    component: Admin,
  },
];
