import AMapLoader from "@amap/amap-jsapi-loader";
import { shallowRef } from "@vue/reactivity";
import { defineComponent, onMounted } from "vue";
export default defineComponent({
  setup() {
    let map = shallowRef(null);

    const ininMap = () => {
      AMapLoader.load({
        key: "672130a6c8e7e4cf59542f5199617008",
        version: "2.0",
        plugins: ["AMap.ToolBar", "AMap.Driving"],
        AMapUI: {
          version: "1.1",
          plugins: []
        },
        Loca: {
          version: "2.0.0"
        }
      }).then(AMap => {
        // 官方默认样式有10种 标准(normal) 马卡龙(macaron) 涂鸦(graffiti) 远山黛(whitesmoke)
        // 幻影黑(dark) 草色青(fresh) 极夜蓝(darkblue) 靛青蓝(blue) 月光银(light) 雅士灰(grey)
        map = new AMap.Map("container", {
          mapStyle: "amap://styles/dark",
          viewMode: "3D",
          zoom: 5,
          zooms: [2, 22],
          center: [105.602725, 37.076636]
        });
        let positionArr = [[119.975752, 31.687177]];

        for (let item of positionArr) {
          let marker = new AMap.Marker({
            position: [item[0], item[1]]
          });
          map.add(marker);
        }
      }).catch(e => {// console.log(e);
      });
    };

    onMounted(() => {
      ininMap();
    });
    return {
      map
    };
  }

});