import request from "@/utils/system/request";

export default {
  findByDashboadrd() {
    return request({
      url: "admin/blog/FindByDashboadrd",
      method: "get",
    });
  }
}
